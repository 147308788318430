import React, { useState } from "react";
import { db } from "../Firebase";
import { doc, setDoc, runTransaction } from "firebase/firestore";
import AWSS3 from './AWSS3'

const Admin = () => {


  function importDoc(collection, document, data) {
    setDoc(doc(db, collection, document), data);
  }
  async function updateStats(collection, document, data) {
    let currentData = {};
    var docRef = db.collection(collection).doc(document);
    try {
      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(docRef); 
              currentData = sfDoc.data();
              if(!currentData.FT) currentData.FT = 0
              if(!currentData.FG) currentData.FG = 0
              if(!currentData.TP) currentData.TP = 0
              if(!currentData.AS) currentData.AS = 0
              if(!currentData.ST) currentData.ST = 0
              if(!currentData.BK) currentData.BK = 0
              if(!currentData.RB) currentData.RB = 0
              if(!currentData.TPT) currentData.TPT = 0
              currentData.FT = parseInt(currentData.FT) + parseInt(data.FT);
              currentData.FG = parseInt(currentData.FG) + parseInt(data.FG);
              currentData.TP = parseInt(currentData.TP) + parseInt(data.TP);
              currentData.AS = parseInt(currentData.AS) + parseInt(data.AS);
              currentData.ST = parseInt(currentData.ST) + parseInt(data.ST);
              currentData.BK = parseInt(currentData.BK) + parseInt(data.BK);
              currentData.RB = parseInt(currentData.RB) + parseInt(data.RB);
              currentData.TPT = parseInt(currentData.TPT) + parseInt(data.TPT);
              //db.collection(collection).doc(document).update(currentData);
              transaction.set(docRef, currentData);
            // db.collection(collection).doc(document).set(currentData);
            
        });
        console.log("Update stats transaction successfully committed: ", collection, document, data, currentData);
      } catch (e) {
        console.log("Transaction failed: ", e);
      }
    
  }
  
  async function updateRecords(player, document, data, stat){
    console.log('_log updateRecords: ', player, document, data, stat)
    var docRef = db.collection("AllTimeRecords").doc(data.League).collection(document).doc('Players');
    try {
      await runTransaction(db, async (transaction) => {
        const sfDoc = await transaction.get(docRef);
        if (!sfDoc.exists()) {
          console.log("_log Document doesn't exist, please create a collection for: " + data.League + " -> " + document + " -> Players")
          //if it doesn't exist, create it
          addSubcollection("AllTimeRecords", data.League, stat, document, data);
        }
        let currentData = sfDoc.data()
        console.log('_log currentData: ', currentData)
        let newPlayer = {
          Score: data[stat],
          Name: player,
          Team: data['Team']
        };
        console.log('_log newPlayer: ', newPlayer)
        if (
          parseInt(data[stat]) >
            parseInt(currentData.Players[currentData.Players.length - 1].Score)
        ) {
          currentData.Players.pop();
          currentData.Players.push(newPlayer);
        }
        currentData.Players.sort(function (a, b) {
          return parseInt(b.Score) - parseInt(a.Score);
        });        
        transaction.update(docRef, currentData);
      });
      console.log("Update records transaction successfully committed: ", player, document, stat, data);
    } catch (e) {
      console.log("Transaction failed: ", e);
    }
  }

  function addSubcollection(
    collection,
    document,
    subcollection,
    subcollectiondocument,
    data
  ) {
    console.log(
      "_log addSubcollection: ",
      collection,
      document,
      subcollection,
      subcollectiondocument,
      data
    );
    //collection, document, gameid, player
    db.collection(collection)
      .doc(document)
      .collection(subcollection)
      .doc(subcollectiondocument)
      .set(data);
  }
  function importArrayToFirebase(array, collection) {
    if (collection === "Teams") {
      array.forEach((element) => {
        let data = {
          League: element.League,
          Season: element.Season,
          Wins: element.Wins,
          Losses: element.Losses,
        };
        importDoc(collection, element.ID, data);
        //add functionality to incremement standings score, when WIN or LOSS
      });
    } else if (collection === "TeamPlayers") {
      array.forEach((element) => {
        let playerName = element["First Name"] + " " + element["Last Name"];
        let data = {
          Team: element.Team,
          "First Name": element["First Name"],
          "Last Name": element["Last Name"],
        };
        addSubcollection("Teams", element.ID, "Players", playerName, data);
      });
    } else if (collection === "Games") {
      console.log("_log Games array: ", array);
      array.forEach((element) => {
        let data = {
          Date: element.Date,
          Location: element.Location,
          HOME: element.HOME,
          AWAY: element.AWAY,
          Time: element.Time,
          Week: element.Week,
          League: element.League,
          Season: element.Season,
        };
        importDoc(collection, element.ID, data);
      });
    } else if (collection === "GameResults") {
      array.forEach((element) => {
        let team1Data = {
          Team: element.Team1,
          Score: element.Team1Score,
          Outcome: element.Team1Outcome,
        };
        let team2Data = {
          Team: element.Team2,
          Score: element.Team2Score,
          Outcome: element.Team2Outcome,
        };
        importDoc("GameResults", element.ID, {
          Week: element.Week,
          Team1Score: element.Team1Score,
          Team2Score: element.Team2Score,
        });
        addSubcollection(
          "GameResults",
          element.ID,
          "Teams",
          element.Team1,
          team1Data
        );
        addSubcollection(
          "GameResults",
          element.ID,
          "Teams",
          element.Team2,
          team2Data
        );
      });
    } else if (collection === "PlayerResults") {
      array.forEach((element) => {
        let resultsData = {
          Team: element.Team,
          Position: element.Position,
          [element.ID]: [
            element.FT,
            element.FTA,
            element.FG,
            element.FGA,
            element.TPT,
            element.TPTA,
            element.AS,
            element.ST,
            element.BK,
            element.RB,
            element.TP,
          ],
        };
        let statsData = {
          League: element.League,
          Team: element.Team,
          FT: element.FT,
          FTA: element.FTA,
          FG: element.FG,
          FGA: element.FGA,
          TPT: element.TPT,
          TPTA: element.TPTA,
          AS: element.AS,
          ST: element.ST,
          BK: element.BK,
          RB: element.RB,
          TP: element.TP,
        };
        //Upload game player stats
        addSubcollection(
          "GameResults",
          element.ID,
          "Players",
          element.Player,
          resultsData
        );
        if (!element.Player.includes('SUB')) {
          let playersDocRef = db.collection("Players").doc(element.Player);
          playersDocRef.get().then(function (thisDoc) {
            if (thisDoc.exists) {
              updateStats("Players", element.Player, statsData);
            } else {
              importDoc("Players", element.Player, statsData);
            }
          });
          let allTimeStatLeadersDocRef = db
            .collection("AllTimeStatLeaders")
            .doc(element.Player);
            allTimeStatLeadersDocRef.get().then(function (thisDoc) {
            if (thisDoc.exists) {
              updateStats("AllTimeStatLeaders", element.Player, statsData);
            } else {
              importDoc("AllTimeStatLeaders", element.Player, statsData);
            }
          });
          //updateRecords("AllTimeRecords", element.Player, statsData);
          updateRecords(element.Player, "3's", statsData, 'TPT')
          updateRecords(element.Player, "Blocks", statsData, 'BK')
          updateRecords(element.Player, "Assists", statsData, 'AS')
          updateRecords(element.Player, "Steals", statsData, 'ST')
          updateRecords(element.Player, "Points", statsData, 'TP')
          updateRecords(element.Player, "Rebounds", statsData, 'RB')
        }
      });
    }else if (collection === "PlayoffBracket") {
      let league = document.querySelector("input.league").value;
      let season = document.querySelector("input.season").value;
      let winner = document.querySelector("input.winner").value || ""
      array.forEach((element) => {
        let resultsData = {
          Round: element.Round,
          Time: element.Time,
          Team1: element.Team1,
          Team1Score: element.Team1Score,
          Team1Result: element.Team1Result,
          Team2: element.Team2,
          Team2Score: element.Team2Score,
          Team2Result: element.Team2Result
        };
        let winnerData = {
          Winner: winner
        }
        let round = "Round " + element.Round
        importDoc("PlayoffBracket", league, winnerData)
        addSubcollection("PlayoffBracket", league, season, round, resultsData)
      })
    }
  }
  function submitPOTW(e, collection) {
    e.preventDefault();
    let x = document.querySelector("form.potw").elements;
    let data = {
      FirstName: x[0].value,
      LastName: x[1].value,
      Team: x[2].value,
      Score: x[3].value,
      Week: x[4].value,
      Division: x[5].value,
    };
    //importDoc(collection, 'Week ' + x[4].value, data)
    addSubcollection("POTW", x[5].value, "Week", x[4].value, data);
  }
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);

  const fileReader = new FileReader();

  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };
  const csvFileToArrayWithoutImport = (string, collection, week, date) => {
    //console.log('_log week: ', week)
    console.log(string)
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const newString = string.split("Team Statistics")[0]
    let csvRows = newString.slice(newString.indexOf("\n") + 1).split("\r\n");
    console.log('_log date: ', date)
    console.log('_log original csv rows: ', csvRows)
    const teamScore = csvRows[0].split(",  ");
    const homeTeam = teamScore[0].split(" (Home) ")
    const awayTeam = teamScore[1].split(" (Away) ")
    const gameId = date+'-'+homeTeam[0]+'|'+awayTeam[0]
    //console.log('_log gameId: ', gameId)
    const statHeaders = csvRows[4].split(",")
    //console.log('_log team: ', teamScore, "home team: ", homeTeam, 'away team: ',  awayTeam, ' stat headers: ', statHeaders)
    let playerCsvRows = csvRows.splice(5)
    //console.log('_log player csv rows: ', playerCsvRows)
    let homeTeamPlayersList = playerCsvRows.splice(0, playerCsvRows.indexOf(playerCsvRows.find(x => x.includes(homeTeam[0]))))
    let awayTeamPlayersList = playerCsvRows.splice(playerCsvRows.indexOf(playerCsvRows.find(x => x.includes(awayTeam[0])))+1)
    awayTeamPlayersList = awayTeamPlayersList.filter(x => x)
    //setArray(array);
    //console.log('_log home player list: ', homeTeamPlayersList)
    //console.log('_log away player list: ', awayTeamPlayersList)
    
    /////////////////////
    //Team Record Update
    /////////////////////
    // let outcome;
    // let homeTeamWins;
    // let homeTeamLosses;
    // let awayTeamWins;
    // let awayTeamLosses;
    // //JOSH NOTE: We need to get the current team wins/losses in order to incremement...
    // if(homeTeam[1] > awayTeam[1]){
    //   outcome = {'home':'WIN','away':'LOSS'}
    //   homeTeamWins = //current wins + 1
    //   awayTeamLosses = //current losses + 1
    // }
    // if(homeTeam[1] < awayTeam[1]){
    //   outcome = {'home':'LOSS','away':'WIN'}
    //   awayTeamWins = //current wins + 1
    //   homeTeamLosses = //current losses + 1
    // } 
    //   let homedata = {
    //     League: 'd1-sunday-league',
    //     Season: 'mecca-pro-am',
    //     Wins: homeTeamWins,
    //     Losses: homeTeamLosses,
    //   };
    //   importDoc("Teams", homeTeam, homedata);
    //   let awaydata = {
    //     League: 'd1-sunday-league',
    //     Season: 'mecca-pro-am',
    //     Wins: awayTeamWins,
    //     Losses: awayTeamLosses,
    //   };
    //   importDoc("Teams", awayTeam, awaydata);
    /////////////////////////////////////////////////////////////////////
    ////GAME UPLOAD
    /////////////////////////////////////////////////////////////////////
    let outcome;
    if(homeTeam[1] > awayTeam[1]) outcome = {'home':'WIN','away':'LOSS'}
    if(homeTeam[1] < awayTeam[1]) outcome = {'home':'LOSS','away':'WIN'}
    let team1Data = {
      Team: homeTeam[0],
      Score: homeTeam[1],
      Outcome: outcome.home,
    };
    let team2Data = {
      Team: awayTeam[0],
      Score: awayTeam[1],
      Outcome: outcome.away,
    };
    importDoc("GameResults", gameId, {
      Week: week,
      Team1Score: homeTeam[1],
      Team2Score: awayTeam[1],
    });
    addSubcollection(
      "GameResults",
      gameId,
      "Teams",
      homeTeam[0],
      team1Data
    );
    addSubcollection(
      "GameResults",
      gameId,
      "Teams",
      awayTeam[0],
      team2Data
    );

    let noStatsCheckbox = document.getElementById('noStats');


    //console.log('_log game results: ', team1Data, team2Data);
      homeTeamPlayersList.forEach((player) => {
        player = player.split(',')
        //console.log('_log player: ', typeof(player), player)
        //ft, fta, fg, fga, 3pt, 3pta, as, st, bk, rb, total points
        //statHeaders: 12, 13, 2, 3, 5, 6, 25, 30, 31, 24, 8
        let resultsData = {
          Team: homeTeam[0],
          Number: player[1],
          [gameId]: [
            player[12],
            player[13],
            player[2],
            player[3],
            player[5],
            player[6],
            player[25],
            player[30],
            player[31],
            player[24],
            player[15]
          ]
        }
        //console.log('_log resultData: ', resultsData)
        let statsData = {
          League: 'd1-sunday-league',
          Team: homeTeam[0],
          FT: player[12],
          FTA: player[13],
          FG: player[2],
          FGA: player[3],
          TPT: player[5],
          TPTA: player[6],
          AS: player[25],
          ST: player[30],
          BK: player[31],
          RB: player[24],
          TP: player[15],
        };
        //console.log('_log '+player[0]+ ' statsData: ', statsData)
        addSubcollection(
          "GameResults",
          gameId,
          "Players",
          player[0],
          resultsData
        );
          if(!noStatsCheckbox.checked){
            if (!player[0].includes('SUB')) {
              //console.log("_log player isn't a sub")
              let playersDocRef = db.collection("Players").doc(player[0]);
              playersDocRef.get().then(function (thisDoc) {
                if (thisDoc.exists) {
                  //console.log('_log updating player... ', statsData)
                  updateStats("Players", player[0], statsData);
                } else {
                  //console.log('_log importing player... ', statsData)
                  importDoc("Players", player[0], statsData);
                }
              });
              let allTimeStatLeadersDocRef = db
                .collection("AllTimeStatLeaders")
                .doc(player[0]);
                allTimeStatLeadersDocRef.get().then(function (thisDoc) {
                if (thisDoc.exists) {
                  updateStats("AllTimeStatLeaders", player[0], statsData);
                } else {
                  importDoc("AllTimeStatLeaders", player[0], statsData);
                }
              });
              updateRecords(player[0], "3's", statsData, 'TPT')
              updateRecords(player[0], "Blocks", statsData, 'BK')
              updateRecords(player[0], "Assists", statsData, 'AS')
              updateRecords(player[0], "Steals", statsData, 'ST')
              updateRecords(player[0], "Points", statsData, 'TP')
              updateRecords(player[0], "Rebounds", statsData, 'RB')
            }
          }
        
      });
      //////////////////////////////////////////
      ////AWAY TEAM PLAYER LIST
      //////////////////////////////////////////
      awayTeamPlayersList.forEach((player) => {
        player = player.split(',')
        //console.log('_log player: ', typeof(player), player)
        //ft, fta, fg, fga, 3pt, 3pta, as, st, bk, rb, total points
        //statHeaders: 12, 13, 2, 3, 5, 6, 25, 30, 31, 24, 8
        let resultsData = {
          Team: awayTeam[0],
          Number: player[1],
          [gameId]: [
            player[12],
            player[13],
            player[2],
            player[3],
            player[5],
            player[6],
            player[25],
            player[30],
            player[31],
            player[24],
            player[15]
          ]
        }
        //console.log('_log resultData: ', resultsData)
        let statsData = {
          League: 'd1-sunday-league',
          Team: awayTeam[0],
          FT: player[12],
          FTA: player[13],
          FG: player[2],
          FGA: player[3],
          TPT: player[5],
          TPTA: player[6],
          AS: player[25],
          ST: player[30],
          BK: player[31],
          RB: player[24],
          TP: player[15],
        };
        console.log('_log '+player[0]+ ' statsData: ', statsData)
        addSubcollection(
          "GameResults",
          gameId,
          "Players",
          player[0],
          resultsData
        );
          if(noStatsCheckbox.checked){
            if (!player[0].includes('SUB')) {
              //console.log("_log player isn't a sub")
              let playersDocRef = db.collection("Players").doc(player[0]);
              playersDocRef.get().then(function (thisDoc) {
                if (thisDoc.exists) {
                  //console.log('_log updating player... ', statsData)
                  updateStats("Players", player[0], statsData);
                } else {
                  //console.log('_log importing player... ', statsData)
                  importDoc("Players", player[0], statsData);
                }
              });
              let allTimeStatLeadersDocRef = db
                .collection("AllTimeStatLeaders")
                .doc(player[0]);
                allTimeStatLeadersDocRef.get().then(function (thisDoc) {
                if (thisDoc.exists) {
                  updateStats("AllTimeStatLeaders", player[0], statsData);
                } else {
                  importDoc("AllTimeStatLeaders", player[0], statsData);
                }
              });
              updateRecords(player[0], "3's", statsData, 'TPT')
              updateRecords(player[0], "Blocks", statsData, 'BK')
              updateRecords(player[0], "Assists", statsData, 'AS')
              updateRecords(player[0], "Steals", statsData, 'ST')
              updateRecords(player[0], "Points", statsData, 'TP')
              updateRecords(player[0], "Rebounds", statsData, 'RB')
            }
          }
      });
  };
  const csvFileToArray = (string, collection) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    console.log('_log csvHeader: ', csvHeader)
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
    console.log('_log csvRows: ', csvRows)
    const array = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object, header, index) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
    importArrayToFirebase(array, collection);
  };

  const handleOnSubmit = (e, collection) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArray(text, collection);
      };

      fileReader.readAsText(file);
    }
  };

  const newHandleOnSubmit = (e, collection, week, date) => {
    e.preventDefault();
    if(file) {
      fileReader.onload = function (event) {
        const text = event.target.result;
        csvFileToArrayWithoutImport(text, collection, week, date)
      }
      fileReader.readAsText(file);
    }
  }
  const headerKeys = Object.keys(Object.assign({}, ...array));

  return (
    <div className="page">
    <h1>new import process </h1>
      <br></br>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />
        <input
          type={"text"}
          id={"week"}
          label={"week"}
        />
        <input
          type={"text"}
          id={"date"}
          label={"date - mddyyyy"}
          />
          <label><input type="checkbox" id="noStats"/> No Stats Upload</label>


        <button
          onClick={(e) => {
            let week = document.getElementById('week').value;
            let date = document.getElementById('date').value;
            console.log('_log stuff: ', week, date)
            newHandleOnSubmit(e, "new", week, date);
          }}
        >
          IMPORT CSV
        </button>
      </form>
      <h1>Import Teams </h1>
      <a download href="src\templates\mecca_teams_template.csv">
        Download Template
      </a>
      <br></br>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />

        <button
          onClick={(e) => {
            handleOnSubmit(e, "Teams");
          }}
        >
          IMPORT CSV
        </button>
      </form>
      <br></br>
      <h1>Import Team Players </h1>
      <a download href="src\templates\mecca_team_players_import_template.csv">
        Download Template
      </a>
      <br></br>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />
<br></br>
<br></br>
        <button
          onClick={(e) => {
            handleOnSubmit(e, "TeamPlayers");
          }}
        >
          IMPORT CSV
        </button>
      </form>

      <br />
      <h1>Import Game Schedule </h1>
      <a download href="src\templates\mecca_games_template.csv">
        Download Template
      </a>
      <br></br>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />
        <br></br>
        <br></br>

        <button
          onClick={(e) => {
            handleOnSubmit(e, "Games");
          }}
        >
          IMPORT CSV
        </button>
      </form>

      <br />
      <h1>Import Game Results </h1>
      <a download href="src\templates\mecca_game_results_template.csv">
        Download Template
      </a>
      <br></br>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />
        <br></br>
        <br></br>

        <button
          onClick={(e) => {
            handleOnSubmit(e, "GameResults");
          }}
        >
          IMPORT CSV
        </button>
      </form>

      <br />
      <h1>Import Player Results </h1>
      <a download href="src\templates\mecca_player_results_template.csv">
        Download Template
      </a>
      <br></br>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />
        <br></br>
        <br></br>

        <button
          onClick={(e) => {
            handleOnSubmit(e, "PlayerResults");
          }}
        >
          IMPORT CSV
        </button>
      </form>
      <br></br>
      <h1>Import Playoff Bracket </h1>
      <br></br>
      <form>
        <input type="text" class="league" placeholder="League"/>
        <input type="text" class="season" placeholder="Season"/>
        <input type="text" class="winner" placeholder="Winner"/>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />

        <button
          onClick={(e) => {
            handleOnSubmit(e, "PlayoffBracket");
          }}
        >
          IMPORT CSV
        </button>
      </form>
      {/* <br />
      <h1>Import Player Stats </h1>
      <a download href="src\templates\mecca_player_results_template.csv">Download Template</a>
      <br></br>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />

        <button
          onClick={(e) => {
            handleOnSubmit(e, "PlayerResults");
          }}
        >
          IMPORT CSV
        </button>
      </form> */}
      <h2>POTW Upload</h2>
      <form className="potw">
        <input type="text" placeholder="First Name"></input>
        <input type="text" placeholder="Last Name"></input>
        <input type="text" placeholder="Team"></input>
        <input type="text" placeholder="Scores"></input>
        <input type="number" placeholder="Week"></input>
        <input type="text" placeholder="Division"></input>
        <button
          onClick={(e) => {
            submitPOTW(e, "POTW");
          }}
        >
          Submit
        </button>
      </form><br></br><br></br>
      <AWSS3></AWSS3>
      <br />
      <table>
        <thead>
          <tr key={"header"}>
            {headerKeys.map((key) => (
              <th>{key}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {array.map((item) => (
            <tr key={item.id}>
              {Object.values(item).map((val) => (
                <td>{val}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Admin;
